(function(w,d,$) {
    var timer = 2000;
    var messages = [];
    var log = function(params) {
        messages.push(params);
    };
    w.onerror = function(message, source, lineno, colno, error) {
        if(typeof('message')==='string') {
            log([message, source, lineno, colno]);
        }
    };
    var looper = function() {
        if(messages.length>0) {
            try {
               $.post('/site/error-log',{log:JSON.stringify(messages)});
            } catch(e) {
            } finally {
               messages = [];
            }
        }
        setTimeout(looper, timer);
    };

    $(d).ready(function() {
        setTimeout(looper, timer);
    });
})(window,document,jQuery);