;(function($){
    if($(window).width() < 768) {
        var collapsible = $('.js-collapsible');
        if(collapsible.length) {
            var height = collapsible.height();

            if(height > 100) {
                collapsible.wrap('<div class="collapse-offset"><div class="collapse-content collapse-hidden"></div></div>');
                collapsible.closest('.collapse-offset').append('<a class="collapse-read-more button" href="javascript:;">'+wwdh.t('Több')+'</a>');

                var btn = $('.collapse-read-more');
                var collapseOffset = $('.collapse-offset');
                var collapseContent = $('.collapse-content');

                btn.on('click', function() {
                    $('html, body').animate({
                        scrollTop: collapseOffset.offset().top - 140
                    }, 400);

                    collapseContent.toggleClass('collapse-hidden');

                    if(collapseContent.hasClass('collapse-hidden')) {
                        btn.text(wwdh.t('Több'));
                    } else {
                        btn.text(wwdh.t('Kevesebb'));
                    }
                });
            }
        }
    }
})(jQuery);